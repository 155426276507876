import { FaDollarSign, FaGlobe, FaHeadSideCough, FaLinkedin, FaMoneyBill, FaPersonBooth, FaRegMap, FaUsers } from 'react-icons/fa';
import "./JobPreview.scss";
import image from "../jupiter.png";
import RTE from '../RTE';

export default function JobPreview({ jobData, isPublish, isView, handleAction1, handleAction2  }) {
    console.log('>>>>JOB DATA>>>', jobData);
    return <div className='p-3 h-100'>
        <div className='d-flex flex-wrap'>
            <div className='job-prev-company-box'>
                <img src={image} height="40" style={{ margin: "auto", marginRight: "16px" }} />
            </div>
            <div className='preview-header-card'>
                <div className='d-flex'>
                    <span className='company-name'>{jobData.companyName}</span>
                    <FaGlobe className='m-1' />
                    <FaLinkedin className='m-1' />
                </div>
                <div className='d-flex opacity-75 flex-wrap row-gap-2'>
                    <div className='d-flex align-items-center'>
                        <FaRegMap className="mr-2" />
                        <span>{jobData.jobLocationCity}</span>
                        <span>&nbsp;|&nbsp;</span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <FaUsers className="mr-2" />
                        <span>{jobData.companySize} employees</span>
                        <span>&nbsp;|&nbsp;</span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <FaMoneyBill className="mr-2" />
                        <span>{jobData.ctcDetailsIfAny}</span>
                        <span>&nbsp;|&nbsp;</span>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        {jobData.requiredExperience ? <div className="job-chip"> {jobData.requiredExperience}+</div>  : null}

                        {jobData.contractType ? <div className="job-chip"> {jobData.contractType}</div> : null}

                        {jobData.jobType ? <div className="job-chip"> {jobData.jobType}</div> : null}
                    </div>
                </div>
            </div>
            <hr className='normal-divider w-100' />
        </div>
        <div className='preview-content flex-1 mb-4'>
            {jobData.jobDescription && 
              <RTE name="jobDescription" value={jobData.jobDescription} readOnly />}
        </div>
        <div>
            <h6>Skills</h6>
            <div className="job-chips">
                {jobData.skills?.split(',').map((item) => {
                    return <>
                        <div className="skill-chip">
                            {item}
                        </div>
                    </>
                })}
            </div>
        </div>
        <div className='preview-footer-card mt-4'>
            <div>
                <h6 className='title'>{jobData.jobTitle}</h6>
                <div className='posted-by'>Job posted by {jobData.recruiterName}(HR) </div>
            </div>
            {isPublish ? <div className='d-flex align-items-center justify-content-center'>
                {handleAction2 ? <button className='mx-2 btn btn-sub' onClick={handleAction2}>Edit Job</button> : null}
                {handleAction1 ? <button className='mx-2 btn btn-main' onClick={handleAction1}>Publish Job</button> : null}
            </div> : null}
            {isView ? <div className='d-flex align-items-center justify-content-center'>
                {handleAction2 ? <button className='mx-2 btn btn-sub' onClick={handleAction2}>Skip Job</button> : null}
                {handleAction1 ? <button className='mx-2 btn btn-main' onClick={handleAction1}>Apply Job</button> : null}
            </div> : null}
        </div>
    </div>
}
