import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import image from "./jobify.png";
import axiosInstance from "../axiosInstance";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setTheme, setUser } from "../redux/slices/auth/authSlice";


const Login = () => {
  const [email, setEmail] = useState("");
  const [isCandidate, setIsCandidate] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecruite, setIsRecruite] = useState(true);

  const [activeTab, setActiveTab] = useState("recruiter");

  const dispatch = useDispatch();

  useEffect(() => {
    const user = localStorage.getItem('user');
    const role = localStorage.getItem('role');
    if (user) {
      dispatch(setUser(user));
      if(role === 'candidate') {
        navigate('/candidates-view');
      }
    } else {
      dispatch(setTheme('light'));
    }
  }, []);

  const handleTabSwitch = (tab) => {
    console.log(tab)
    setActiveTab(tab);
    console.log(activeTab)

  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  function setvalue() {
    if (activeTab === "recruiter") {
      setIsRecruite(true);
    } else {
      setIsRecruite(false);
    }
  }
  const handleContinue = () => {
    setIsLoading(true);
    console.log(activeTab)
    // setvalue()

    console.log("hello")
    console.log(isRecruite)
    if (validateEmail(email)) {
      axiosInstance
        .post(`v1/apis/login/otp?email=${encodeURIComponent(email)}&isRecruiter=${activeTab === "recruiter" ? true : false}`)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setIsLoading(false);

            // alert("Form submitted!");
            // window.location.href = '/otp';
            handleNavigation();
          } else {
            // alert("Failed to submit form. Please try again.");
          }
        })
        .catch((error) => {

          setIsLoading(false);
          toast.error("You are not a recruiter partner with us yet! Please contact hello@jobify.club")
          setEmail("")
          console.error("Error:", error);
        });
    } else {
      setIsLoading(false);

      setEmailError(true);
    }
  };
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/otp", { state: { role: activeTab, email: email } });
  };

  const isEnterPressed = (event) => {
    return event.code === "Enter" || event.code === "NumpadEnter";
  }

  useEffect(() => {
    const listener = event => {
      if (isEnterPressed(event)) {
        event.preventDefault();
        handleContinue();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, activeTab])

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="frame">
          <a href="/" className="jobify-image" >
            <img src={image} alt="Jobify" width={100}/>
          </a>
          <div className="div">
            <div className="text-wrapper">Login</div>
            <div className="div-3 p-1" style={{ backgroundColor: "#e6e6e6", borderRadius: "2rem" }}>
              <div className="d-flex w-100">
                <nav variant="tabs" className="custom-tabs">
                  {/* <Nav.Item> */}
                  <Nav.Link style={{ borderRadius: "2rem" }}
                    eventKey="#recruiter"
                    className={
                      activeTab === "recruiter"
                        ? "active custom-tab"
                        : "custom-tab"
                    }
                    onClick={() => handleTabSwitch("recruiter")}
                  >
                    Recruiter
                  </Nav.Link>
                  {/* </Nav.Item> */}
                  {/* <Nav.Item> */}
                  <Nav.Link style={{ borderRadius: "2rem" }}
                    eventKey="#candidate"
                    className={
                      activeTab === "candidate"
                        ? "active custom-tab"
                        : "custom-tab"
                    }
                    onClick={() => handleTabSwitch("candidate")}
                  >
                    Candidate
                  </Nav.Link>
                  {/* </Nav.Item> */}
                </nav>
              </div>
            </div>
            <div className="div-3 w-100">
              <div className="text-wrapper-2">Email*</div>
              <div className="div-4 w-100">
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  className={emailError ? "error" : ""}
                  placeholder="Enter your email"
                />
                {emailError && (
                  <div className="text-wrapper-3">Invalid email format</div>
                )}
              </div>
            </div>
            <div className="link">
              <button className="text-wrapper-4 w-100" onClick={handleContinue}>
                {isLoading ? (
                  <div>
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    &nbsp; &nbsp;
                    <span>Loading...</span>
                  </div>
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4 align-self-start">
          <p className="text-start">
            Having Trouble? &nbsp;
            <span>
              <a href="/contact-us">Contact Us </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;