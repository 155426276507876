import React from 'react';

// import CodeMirror from '@uiw/react-codemirror';
// import { loadLanguage } from '@uiw/codemirror-extensions-langs';
// import * as themes from '@uiw/codemirror-themes-all';
import { useAppSelector } from '../../redux/hooks';
import { Editor } from '@monaco-editor/react';

const CodeEditor = ({ language, sourceCode, setSourceCode }) => {

  const { theme } = useAppSelector(state => state.auth);

  const handleValidate = (markers) => {
    // model markers
    markers.forEach((marker) => {
      console.log(marker, 'onValidate:', marker.message)
    });
  }

  /*return (
    <CodeMirror
      extensions={[loadLanguage(language)]} 
      theme={theme === 'light' ? themes.githubLight : themes.vscodeDark}
      value={sourceCode}
      onChange={setSourceCode}
    />
  )*/

  return (
    <Editor
      height="100%"
      language={language}
      theme={theme === 'light' ? '' : 'vs-dark'}
      defaultLanguage='javascript'
      defaultValue='// some comment\nconst x = 1;'
      value={sourceCode}
      onChange={setSourceCode}
      onValidate={handleValidate}
    />
  )
}

export default CodeEditor;