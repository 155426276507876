import React, { useEffect, useState } from 'react';
import '../styles/toggle.css';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setTheme } from '../redux/slices/auth/authSlice';

function Toggle( ) {

  const { theme } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

    // false = dark mode because of the way I wrote the CSS
    const [active, setActive] = useState(false)
    // the opposite, for screenreaders

    const changeThemeAndToggle = () => {
      if (theme === 'light') {
        dispatch(setTheme('dark'));
      } else {
        dispatch(setTheme('light'));
      }
    }

    const handleOnClick = () => {
      changeThemeAndToggle()
    }

    const handleKeypress = e => {
      if (e.code === "Enter") {
        changeThemeAndToggle()
      }
    }

    useEffect(() => {
      if (theme === 'dark') {
        setActive(false)
      } else if (theme === 'light') {
        setActive(true)
      }
    }, [theme])

    return (
      <div className="container--toggle">
        <input role="switch" aria-checked={!active} onKeyDown={handleKeypress} type="checkbox" id="toggle" className="toggle--checkbox" onClick={handleOnClick} checked={active} readOnly />
        <label htmlFor="toggle" className="toggle--label">
          <span className="toggle--label-background"></span>
        </label>
      </div>
    )
}

export default Toggle;