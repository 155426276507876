import React, { ReactNode, type HTMLProps } from "react";
import { type FieldErrors, type FieldValues, useController, type UseFormReturn } from "react-hook-form";
import _ from "lodash";
import clsx from "clsx";
import If from "../If";
import './index.scss';

interface ICommonInputProps {
  id?: string;
  type?: 'text' | 'number' | 'password';
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  className?: HTMLProps<HTMLElement>["className"];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

}

interface IControlledInputProps extends ICommonInputProps {
  control?: UseFormReturn["control"];
  errors?: FieldErrors<FieldValues>;
}

interface IUncontrolledInputProps extends ICommonInputProps {
  register?: UseFormReturn["register"];
  errors?: FieldErrors<FieldValues>;
}

type IInputProps = IControlledInputProps & IUncontrolledInputProps;

const AppInput = ({
  id, type = 'text', name, label, value, placeholder, required, disabled, errorMessage, className,
  onChange, register, control, errors
}: IInputProps) => {
  if (register || control) {
    const error = _.get(errors || {}, name);
    let inputProps = null;
    if (register) {
      inputProps = register(name); // UnControlled way of handling input
    }
    // else if (control) {
    //   const { field } = useController({ name, control }); // Controlled way of handling input
    //   inputProps = field;
    // }
    return (
      <div className="tw-w-full">
        {label ? <label htmlFor={id} className={clsx("input-label", { required })}>{label}</label> : null}
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={clsx('form-control', { invalid: error })}
          {...inputProps}
          onChange={(e) => {
            inputProps?.onChange(e);
            onChange?.(e);
          }}
          onBlur={(e) => {
            inputProps?.onBlur(e);
          }}
        />
        <If condition={!!error}>
          <div className="error tw-ml-0.5 tw-mt-0.5 tw-text-sm">{error?.message as ReactNode}</div>
        </If>
      </div>
    )
  }
  // else {
  //   return (
  //     <div className="form-input">
  //       {label ? <label htmlFor={id} className="input-label">{label} {required ? <span className="required">*</span> : ''}</label> : null}
  //       <input
  //         id={id}
  //         type={type}
  //         name={name}
  //         value={value}
  //         placeholder={placeholder}
  //         disabled={disabled}
  //         className={clsx({ invalid: !!errorMessage })}
  //         onChange={(e) => {
  //           onChange?.(e);
  //         }}
  //       />
  //       <If condition={!!errorMessage}>
  //         <div className="error tw-ml-0.5 tw-mt-0.5">{errorMessage}</div>
  //       </If>
  //     </div>
  //   )
  // }
};

export default AppInput;
