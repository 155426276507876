import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import recordContext from "../context/recordContext";
import { toast } from "react-toastify";


const GrantPermissions = () => {
  const navigate = useNavigate();
  const context = useContext(recordContext);
  const [stepper, setStepper] = useState(0);
  const [searchParams] = useSearchParams();

  const {
    captureScreen,
    captureUserMedia,
    togglePiP,
    startRecording,
} = context;

  const getUserMediaStatus = async () => {
    return await captureUserMedia();
  }

  const shareCamera = async () => {
      const status2 = await getUserMediaStatus();
      if (status2 === 'SUCCESS') {
          setStepper(2);
      } else {
          toast.error("Kindly enable camera and microphone settings");
      }
  }

  const grantPermissions =  async () => {
      const status = await captureScreen();
      if(status === 'SUCCESS') {
          const status2 = await getUserMediaStatus();
          if(status2 === 'SUCCESS') {
              setStepper(2);
          } else {
              setStepper(1);
          }
      }
  }

  const handleStartRecording = async () => {
      const status  = await togglePiP();
      if(status === 'SUCCESS') {
          const status2 = await startRecording();
          if(status2 === 'SUCCESS') {
              // window.open(`code?`, "_blank")
              navigate(`/code?jobId=${searchParams.get('jobId')}`);
          }
      }
  }

  return (
    <div className="fixed-bottom text-center permission-footer">
        <div className="d-flex align-items-center justify-content-around">
            <div>
                <p className="m-0">Grant permissions</p>
            </div>
            <div>
                <button className="btn btn-sub mr-2" onClick={() => { navigate('/candidates-view'); }}>Go back</button>

                {stepper == 0 && <button type="button" className="btn btn-main" onClick={grantPermissions}>Grant permission</button>}

                {stepper == 1 && <button
                    type="button"
                    className="btn btn-main"
                    onClick={shareCamera}
                >
                    Share camera
                </button>}

                {stepper == 2 && <button
                    type="button"
                    className="btn btn-main"
                    onClick={handleStartRecording}
                >
                    Take Test
                </button>}
            </div>
        </div>
        </div>
  )
}

export default GrantPermissions;