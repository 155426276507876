import React from "react";

export default function Contactus() {
  return (
    <div>
      <div className="container p-5">
        <body>
          <h1 Style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Contact Us
          </h1>
          <p Style="padding-top: 19pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">
            Jobify Inc. - we are all ears on hello@jobify.club , please feel
            free to drop in any queries.
          </p>
        </body>
      </div>
    </div>
  );
}
