import React, { useState } from 'react';
import FormFooter from './FormFooter';


import './index.scss';
import { Form } from 'react-bootstrap';
import RTE from '../../RTE';

function Step2_1({ formData, onDataChange, handleNext, handleBack, previewJob }) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            handleNext();
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };

    const onChange = (value) => {
        onDataChange((prev) => ({
            ...prev, 
            ['questionText']: value
        }));
    }

    return (
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <RTE name="questionText" value={formData.questionText} onChange={onChange} />
          <FormFooter formData={formData} showPrev={true} handleBack={handleBack} />
      </Form>
    );
}

export default Step2_1;