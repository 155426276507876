import { useState } from 'react';
import FormFooter from './FormFooter';
import './index.scss';
import RTE from '../../RTE';

function FormExample({ formData, onDataChange, handleNext, handleBack, previewJob }) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            handleNext();
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };

    const onChange = (value) => {
        onDataChange((prev) => ({
            ...prev, 
            ['jobDescription']: value
        }));
    }

    return (
        <>
            <RTE name="jobDescription" value={formData.jobDescription} onChange={onChange} />
            <FormFooter formData={formData} showPrev={true} isFinal={true} handleBack={handleBack} previewJob={previewJob} />
        </>
    );
}

export default FormExample;