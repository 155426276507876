import React, { useEffect, useState } from "react";
import axios from "axios";
import image from "./jupiter.png";
import axiosInstance from "../axiosInstance";
import { useLocation } from "react-router-dom";
import { FaRegMap } from "react-icons/fa";
import AppNav from "./AppNav";
import { toast } from "react-toastify";

export default function AppliedCandidates({ selectedJobId }) {
  const [applied, setapplied] = useState([]);
  const location = useLocation();
  const jobid = location.state.jobid;

  const getsuccessfulcandidates = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("auth"))
      // Send a POST request with the email, role, and entered OTP
      const response = await axiosInstance.get(
        `/v1/recruiters/candidates/successful?jobPostUUID=${jobid}&token=${token}`
      );
      if (response.data && response.data.length > 0) {
        console.log(response.data);
        setapplied(response.data);
      }
    } catch (error) {
      console.error("Failed to verify OTP", error);
    }
  };
 
  const getFilePreSignedUrl = (fileName) => {
    return axiosInstance.get(`/v1/apis/pre-signed-url?fileName=${fileName}`);
  }
  
  const viewResume = async (fileName) => {
    try {
      const response  = await getFilePreSignedUrl(fileName);
      if (response.data) {
        console.log(response.data);
        window.open(response.data, "_blank");
      }
    } catch (error) {
      toast.error("Failed to view resume");
    }
  };
  const viewVideo = async (fileName) => {
    try {
      const response  = await getFilePreSignedUrl(fileName);
      if (response.data) {
        console.log(response.data);
        window.open(response.data, "_blank");
      }
    } catch (error) {
      toast.error("Failed to view video");
    }
  };
  useEffect(() => {
    getsuccessfulcandidates();
  }, []);
  return (
    <div>
      <AppNav />
      <div className="recruiters-view">
        <div className="active-jobs-list">
          {applied.map((candidate, index) => (
            <div className="job-card d-flex mt-3">
              <div className="d-none d-lg-block">
                <img
                  src={image}
                  alt=""
                  srcset=""
                  height={"50px"}
                />
              </div>
              <div className="flex-1">
                <div className="row d-flex col-12">
                  <img
                    className="d-sm-none col-2 p-0"
                    src={image}
                    alt=""
                    srcset=""
                  />
                  <h5 className="col-10">{candidate.firstName} {candidate.lastName}</h5>
                </div>
                <div className="row d-flex">
                  <div>
                    <span style={{ color: "#3B82F6" }}>
                      {candidate.candidateEmail}
                    </span>
                    <span>&nbsp;| &nbsp;{candidate.phoneNumber}</span>
                  </div>
                </div>
                <div className="job-chips mt-2">
                  {candidate.yoe ? <div className="job-chip"> {candidate.yoe}+</div>  : null}

                  {candidate.noticePeriodInDays ? <div className="job-chip"> {candidate.noticePeriodInDays} days</div> : null}

                  {candidate.expectedCTC ? <div className="job-chip"> {candidate.expectedCTC} LPA</div> : null}
                </div>
              </div>
              <div className="d-flex mt-3">
                <div className="col-12">
                  <button className='mx-2 btn btn-sub' onClick={() => viewVideo(candidate.videoFileName)}>View Video</button>
                  <button className='mx-2 btn btn-main' onClick={() => viewResume(candidate.resumeFileName)}>View Resume</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
