import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormFooter from './FormFooter';
import './index.scss';

function FormExample({ formData, onDataChange, handleNext, handleBack }) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            handleNext();
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        onDataChange((prev) => { return { ...prev, [name]: value } });
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Job Title*</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Position Name"
                        name="jobTitle"
                        value={formData.jobTitle}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Experience required*</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        placeholder="In years"
                        name="requiredExperience"
                        value={formData.requiredExperience}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Job Type*</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Type"
                        defaultValue=""
                        name="jobType"
                        value={formData.jobType}
                        onChange={handleChange}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Compensation (From)*</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        placeholder="Minimum"
                        name="compensationFrom"
                        value={formData.compensationFrom}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Compensation (To)*</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        placeholder="Maximum"
                        name="compensationTo"
                        value={formData.compensationTo}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Contract type</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Type"
                        name="contractType"
                        value={formData.contractType}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Additional compensation*</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        placeholder="Amount in numbers"
                        name="additionalCompensation"
                        value={formData.additionalCompensation}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Row>

            <hr className='divider' />

            <h6>Job location</h6>

            <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Provide work location"
                        name="jobLocationAddress"
                        value={formData.jobLocationAddress}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter city name"
                        name="jobLocationCity"
                        value={formData.jobLocationCity}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter state name"
                        name="jobLocationState"
                        value={formData.jobLocationState}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter country name"
                        name="jobLocationCountry"
                        value={formData.jobLocationCountry}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Row>

            <FormFooter formData={formData} showPrev={true} handleBack={handleBack} />
        </Form>
    );
}

export default FormExample;