import React, { useState, useEffect } from "react";
import { FaRegMap } from "react-icons/fa";
import image from "./jupiter.png";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import './ActiveJobs.scss';

export default function ActiveJobs({ onButtonClick }) {
  const navigate = useNavigate()
  const [data, setData] = useState(null);
  // const handleButtonClick = (jobId) => {
  //   console.log("Selected Job ID:", jobId);
  //   navigate("/applied-candidates-view", { state: { jobid: jobId } });
  // };
  const redirectSucessCandidates = (jobId) => () => {
    navigate(`/sucessful-candidates-view/${jobId}`, { state: { jobid: jobId } } );
  };
  // if (onButtonClick) onButtonClick(jobId);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("auth"));
    const token = authData;

    if (token) {
      axiosInstance
        .get(`v1/recruiters/job-postings/recruiters-view?token=${token}`, {
          headers: {
            // Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  }, []);
  return (
    <div className="active-jobs-list">
      {data &&
        data.map((job) => (<div className="job-card d-flex mt-3">
        <div className="d-none d-lg-block">
            <img
                src={image}
                alt=""
                srcset=""
                height={"50px"}
            />
        </div>

        <div className="flex-1">
            <div className="row d-flex col-12">
                <img
                    className="d-sm-none col-2 p-0"
                    src={image}
                    alt=""
                    srcset=""
                />
                <h5 className="col-10">{job.jobTitle}</h5>
            </div>
            <div className="row d-flex">
                <div>
                    <span style={{ color: "#3B82F6" }}>
                        {job.companyName}
                    </span>
                </div>
            </div>
            <div className="job-chips mt-2">
                <div className="job-chip">
                    <FaRegMap className="mr-2" /> 
                    {job.jobLocationCity}
                </div>
                {job.requiredExperience ? <div className="job-chip"> {job.requiredExperience}+</div>  : null}

                {job.contractType ? <div className="job-chip"> {job.contractType}</div> : null}

                {job.jobType ? <div className="job-chip"> {job.jobType}</div> : null}
            </div>
            <div className="description" dangerouslySetInnerHTML={ { __html: job.jobDescription }}/>
        </div>

        <div className="d-flex mt-3">
          <div className="col-12">
              <button className='mx-2 btn btn-main' onClick={redirectSucessCandidates(job.jobPostUUID)}>Success</button>
          </div>
        </div>
      </div>))}
    </div>
  );
}
