import React, { useEffect, useState } from "react";

import { EditorState, ContentState, convertToRaw, DefaultDraftBlockRenderMap } from 'draft-js';
import { Editor as RichTextEditor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useAppSelector } from "../redux/hooks";
import Immutable from 'immutable';


const blockRenderMap = Immutable.Map({
  'code-block': {
    element: 'section',
  },
  'code': {
    element: 'pre'
  }
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const RTE = ({ name, value, onChange, readOnly = false }) => {
  const { theme } = useAppSelector(state => state.auth);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [styleMap, setStyleMap] = useState({});

  useEffect(() => {
    setStyleMap(getStyledMap());
    setEditorState(EditorState.forceSelection(editorState, editorState.getSelection()))
  }, [theme])

  useEffect(() => {
      if(value) {
        /**
         * It supports both string and draft editor state. By checking type and decide to
         * convert html to editor state
         */
        if(typeof value === 'string') {
          const { contentBlocks, entityMap } = htmlToDraft(value);
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        } else {
          setEditorState(value)
        }
      }
  }, [])

  useEffect(() => {
    if(onChange) {
      onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
      // onChange(editorState);
    }
  }, [editorState]);

  const getStyledMap = () => {
    if(theme === 'dark') {
        return {
            'CODE': {
                backgroundColor: '#3a3838',
              },
        }
    } else {
        return {
            'CODE': {
                backgroundColor: '#f1f1f1',
              },
        }
    }
  }

  return (
    <RichTextEditor
        name={name}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        editorClassName={readOnly ? 'editor-readonly' : 'edit-box'}
        readOnly={readOnly}
        toolbarHidden={readOnly}
        customStyleMap={styleMap} // Ref. https://draftjs.org/docs/advanced-topics-inline-styles/
        blockRenderMap={extendedBlockRenderMap} // ref https://draftjs.org/docs/advanced-topics-custom-block-render-map/
        handlePastedText={() => false} // To copy paste works properly 
        // stripPastedStyles={true} // remove all styles when pasting
     
    />
  );
}

export default RTE;

// handlePastedText =  {handlePastedText} 
// handlePastedText =  {() => false} both are same 
/*
function getBlockSelection(block, start, end) {
	const blockKey = block.getKey();
  return new SelectionState({
    anchorKey: blockKey,
    anchorOffset: start,
    focusKey: blockKey,
    focusOffset: end,
  });
}

function removeEditorStyles(editorState) {
	let newEditorState = editorState;
  let newContent = editorState.getCurrentContent();
	const blocks = newContent.getBlocksAsArray();
  for (let block of blocks) {
  	block.findStyleRanges(() => true, function(start, end) {
      newContent = Modifier.removeInlineStyle(
        newContent,
        getBlockSelection(block, start, end),
        block.getInlineStyleAt(start),
      );
    });
    newEditorState = EditorState.push(newEditorState, newContent, 'change-inline-style');
  }
  
  return newEditorState;
}

const handlePastedText = (text, styles, editorState) => {
  setEditorState(removeEditorStyles(editorState))
};
*/

// https://draftjs.org/docs/advanced-topics-custom-block-render-map/
/*
const blockRenderMap = Immutable.Map({
  'code-block': {
    element: 'pre',
    wrapper: <blockquote className="code-block" />,
  }
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);
blockRenderMap={extendedBlockRenderMap}
*/

/*
  blockRendererFn={myBlockRenderer} 
  const myBlockRenderer = (contentBlock) => {
    const type = contentBlock.getType();
    console.log('type', type);
    if (type === 'atomic') {
      return {
        component: MediaComponent,
        editable: false,
        props: {
          foo: 'bar',
        },
      };
    }
  }

*/

 //   toolbar={{
//      inline: { inDropdown: true },
//      list: { inDropdown: true },
//      textAlign: { inDropdown: true },
//      link: { inDropdown: true },
//      history: { inDropdown: true },
//    }}
//    blockStyleFn={myBlockStyleFn}

// const myBlockStyleFn = (contentBlock) => {
//     const type = contentBlock.getType();
//     console.log(contentBlock, '>>>>>>', type);
//     if (type === 'code-block') {
//       return 'superFancyBlockquote';
//     } else {
//         return 'a'
//     }
// }
