import { useEffect, useState } from "react";
import darkLogo from "./jobify-dark-logo.svg";
import lightLogo from "./jobify-light-logo.svg";
import styles from "./AppNav.module.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Toggle from "./ThemeToggleButton";
import { FaCog, FaPowerOff, FaUser } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import './AppNav.css';
import { logoutUser } from "../redux/slices/auth/authSlice";

export default function AppNav({ showRecruiterTabs }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    console.log('LOCATION >>>>', location);

    useEffect(() => {
        if(location.pathname === '/recruiters-view' && searchParams.get('tab') === null) {
           navigate('/recruiters-view?tab=1');
        }
    }, [location])

    const [showPopup, setShowPopup] = useState(false);

    const { user, theme } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();

    const handlesignout = () => {
        dispatch(logoutUser());
        navigate('/login');
    };

    const handleTabChange = (tabID) => () => {
        searchParams.set('tab', tabID);
        navigate(`${location.pathname}?tab=${tabID}`);
    }

    console.log('SEARCH PARAMS >>>>', searchParams.get('tab'));

    const role = localStorage.getItem('role');
    return <>
        {showPopup && user &&
            <>
                <div className={styles.profilePopupBg} onClick={() => { setShowPopup(false) }}>
                </div>
                <div className={[styles.profilePopup].join(" ")}>
                    <h5 className="account-heading">My account</h5>
                    <div className="d-flex align-items-center mt-3">
                        <div className="avatar mr-2">
                            <FaUser />
                        </div>
                        <div>
                            <span className="user-name">
                                {user.email.split('@')[0]}
                                <div className="role-chip ml-2">
                                    {role === 'candidate' ? 'Candidate profile' : 'Recruiter profile'}
                                </div>
                            </span>
                            <br />
                            <span className="user-email">{user.email}</span>
                        </div>
                    </div>
                    <hr style={{ borderColor: '#E2E8F0'}}/>
                    <div className="signout" onClick={handlesignout}>
                        <FaPowerOff className="mr-2" /> Logout
                    </div>
                </div>
            </>}

        <div className={[styles.appNav].join(" ")}>
            <div className="d-flex justify-content-between align-items-center">
                <img src={theme === 'dark' ? darkLogo : lightLogo} alt="logo" className="z-2" style={{ height: "2.2rem" }} />
                {showRecruiterTabs &&
                    <div className="recruiters-tabs">
                        <div className="d-flex gap-2">
                            <div className={`tab ${searchParams.get('tab') == 1 ? 'active' : ''}`} onClick={handleTabChange(1)}>Post Job</div>
                            <div className={`tab ${searchParams.get('tab') == 2 ? 'active' : ''}`} onClick={handleTabChange(2)}>Active Jobs</div>
                        </div>
                    </div>
                }
                <div className="d-flex align-items-center z-2">
                    <Toggle />
                    <div className="d-flex align-items-center">
                        <div className="avatar mr-2">
                            <FaUser />
                        </div>
                        <span className="user-email mr-2">{user?.email}</span>
                    </div>
                    |
                    <button className="btn" type="button" onClick={() => { setShowPopup((prev) => !prev) }}>
                        <FaCog className="settings-icon"/>
                    </button>
                </div>
            </div>
        </div>
    </>
}
