// CodeEditor.jsx
import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { encode as base64_encode } from "base-64";
import { confirmAlert } from 'react-confirm-alert';

import { reviewSubmit, runCodeAndSubmitRequest, runCodeRequest, submitSolution } from "./../utilities/requests/runCodeUtility";
// import FeedbackModal from "./FeedbackModal";
import "react-toastify/dist/ReactToastify.css";
import "./Editor.scss";
import Split from '@uiw/react-split';


// Import themes and modes for the languages you want to support
import { getBasicTestCasesResult } from "../utilities/testsRunner/testRunnerUtility";
import { constructCodeExecutionResultFromResponseJson } from "../utilities/textFormatting/responseFormatting";

import { DEFAULT_SNIPPET } from "../Questions/questions";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import RTE from "./RTE";
import { DEFAULT_QUESTION } from "../basic";
import { useAppSelector } from "../redux/hooks";
import CodeEditor from "./CodeEditor";
// Add other modes as required

const Editor = () => {
  const { theme, user } = useAppSelector(state => state.auth);

  const [time, setTime] = useState(() => {
    return parseInt(localStorage.getItem("time") || 905); // changed 1 hr to 15 min
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const [languageId, setLanguageId] = useState("javascript");
  const [sourceCode, setSourceCode] = useState(DEFAULT_SNIPPET['javascript'].codeSnippet);

  const [codeResult, setCodeResult] = useState(null);
  // const [selectedRating, setSelectedRating] = useState(0);
  // const [review, setReview] = useState("");
  const [runsubmit, setRunSubmit] = useState(false)

  const [output, setOutput] = useState("");
  const [testMessageClassName, setTestMessageClassName] = useState("hidden");
  const [testCasesResultMessage, setTestCasesResultMessage] = useState("");
  const [isTestPassed, setIsTestPassed] = useState(false);
  const [isCodingRunning, setIsCodeRunning] = useState(false);

  const [isTestCasesRunning, setIsTestCasesRunning] = useState(false);
  const [testCasesResult, setTestCasesResult] = useState([]);
  const [jobData, setJobData] = useState(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleCodeSubmission = useCallback(async () => {
    if (codeResult === null) {
      toast.error("Please Run code before Submit");
    } else {
      const codesubmitted = await codesubmit();
      if (codesubmitted) {
        localStorage.setItem("stopVideoSignal", Date.now().toString());
        setIsModalOpen(true);
      }
    }
  }, [codeResult, sourceCode, setIsModalOpen, codesubmit]);

  useEffect(() => {
    // Prompt confirmation when reload page is triggered
    window.onbeforeunload = (e) => {
      e.preventDefault();
      return ""
    };

    // Unmount the window.onbeforeunload event
    return () => { window.onbeforeunload = null };
}, []);

  useEffect(() => {
    checkValidJob();
  }, []);

  useEffect(() => {
    const interval = time > 0 && setInterval(() => {
      localStorage.setItem("time", time - 1);
      setTime(time - 1)
    }, 1000);
    if (time === 0) {
      handleCodeSubmission();
    }
    if (time === 50) {
      notify();
    }

    return () => clearInterval(interval);
  }, [time, handleCodeSubmission]);

  useEffect(() => {
    setOutput(isCodingRunning ? "Loading..." : codeResult?.output || "");
  }, [isCodingRunning, codeResult]);

  useEffect(() => {
    if (runsubmit) {
      handleCodeSubmission();
    }
  }, [runsubmit]);

  useEffect(() => {
    setIsTestPassed(false);
    if (testCasesResult?.length === 0) {
      return;
    }

    if (testCasesResult.allTestCasesPassed) {
      setIsTestPassed(true);
      setTestMessageClassName("all-testcases-passed");
      setTestCasesResultMessage("Congratulations.. All initial test cases are passed.");
    } else if (testCasesResult.allTestCasesFailed) {
      setTestMessageClassName("all-testcases-failed");
      setTestCasesResultMessage("All initial test cases are failed.");
    } else {
      setTestMessageClassName("some-testcases-failed");
      setTestCasesResultMessage(`${testCasesResult.passedTestCount} out of ${testCasesResult.totalTestCount} initial test cases passed`);
    }

  }, [testCasesResult]);

  const notify = () => {
    toast("5 min left");
  };

  const checkValidJob = () => {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData;
      const jobId = searchParams.get('jobId');

      axiosInstance
      .get(`v1/jobs/eligibility?jobPostUUID=${jobId}&token=${token}`, {
          headers: {
          accept: "*/*",
          },
      })
      .then((response) => {
          // Handle the response data here
          localStorage.setItem("jobId", jobId);
          getJobInfo();
      })
      .catch((error) => {
          toast.error("Please contact us at hello@jobify.club");
          navigate('/candidates-view');
      });
  }

  const getJobInfo = async () => {
    const authData = JSON.parse(localStorage.getItem("auth"));
    const token = authData;
    const jobId = searchParams.get('jobId');
    try {
      const response = await axiosInstance.get(`/v1/jobs/infos?jobId=${jobId}&token=${token}`);
      if(!response.data?.questionText) {
        response.data.questionText = DEFAULT_QUESTION;
      }
      console.log(response.data);
      setJobData(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function codesubmit() {
    const submitSolutionRecord = {
      candidateEmail: JSON.parse(localStorage.getItem("email")),
      codeInput: base64_encode(sourceCode),
      codeOutput: base64_encode(codeResult?.output),
      jobPostUUID: localStorage.getItem("jobId"),
      languageId: Number(languageId),
      isTestPassed: isTestPassed,
    };
    try {
      return await submitSolution(submitSolutionRecord).then(response => response.data);
    } catch (error) {
      throw error;
    }
  }

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguageId(selectedLanguage);
    setSourceCode(DEFAULT_SNIPPET[selectedLanguage]?.codeSnippet || '');
  };
  // Sample url: https://api.jobify.club/v1/video/send-complete?message=dhanushvimal001@gmail.com_jobId=2954271d-be7b-400d-92c7-c4399275893c
  const handleCodeSubmit = () => {
    try {
      const response = axiosInstance.post(`/v1/video/send-complete?message=${user.email}_jobid=${searchParams.get('jobId')}`)
    } catch(error) {

    }
  }

   /*
  const swalfire = async () => {
    try {
      confirmAlert({
        message: 'Are you sure you want to submit the code?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () =>  {
              const decodedValue = await runCodeandSubmit();
            }
          },
          { label: 'No' },
        ]
      });
    } catch (error) {
      console.error(error);
    }
  };
  */

  /*
  const handleRatingChange = (newRating) => {
    setSelectedRating(newRating);
  };

  const handleReviewChange = (newReview) => {
    setReview(newReview);
  };

  const handleCloseWindow = () => {
    window.close();
  };

  const handleClose = () => {
    setIsModalOpen(false);
    handleCloseWindow();
  };
  */

  /*
  const handleSubmit = () => {
    const postData = {
      candidateEmail: JSON.parse(localStorage.getItem("email")),
      jobPostUUID: localStorage.getItem("jobId"),
      review: review,
      stars: selectedRating,
    };

    reviewSubmit(postData)
      .then(function (response) {
        setIsModalOpen(false);
        localStorage.clear();
        handleCloseWindow();
      })
      .catch(function (error) {
        console.error("POST request failed:", error);
      });
  };
  */

  /*
  const setTestResultToBlank = () => {
    setTestMessageClassName("hidden");
    setTestCasesResultMessage("");
    setTestCasesResult([])
  }

  const handleCodeSubmit = () => {
    try {
      const response = axiosInstance.post("/v1/video/send-complete?message=")
    } catch(error) {

    }
  }

  const runCode = async () => {
    try {
      let encoded = base64_encode(sourceCode);
      setIsCodeRunning(true);
      setTestResultToBlank();

      const responseJson = await runCodeRequest(encoded, languageId)
        .finally(() => setIsCodeRunning(false));
      const codeResult = constructCodeExecutionResultFromResponseJson(responseJson);
      setCodeResult(codeResult);

      if (codeResult.areAllStepsCompletedSuccessfully) {
        setIsTestCasesRunning(true);
        const functionText = ''; // For now dummy text added
        const testCases = [];
        setTestCasesResult(await getBasicTestCasesResult(sourceCode, languageId, functionText, testCases))
      }
      return codeResult.output;
    } catch (error) {
      console.log(error);
    } finally {
      setIsTestCasesRunning(false);
    }
  };
  */

  /*
  const runCodeandSubmit = async () => {
    try {
      const responseJson = await runCodeAndSubmitRequest(base64_encode(sourceCode), languageId)
        .then(response => response.json());

      const codeResult = constructCodeExecutionResultFromResponseJson(responseJson);
      setCodeResult(codeResult);

      await handleCodeSubmission();
    } catch (error) {
      console.log(error);
    }
  };
  */

  return (
    <div className="editor-container">
      <div className="editor-header">
        <div className="header-actions">
          <button className='btn btn-run d-flex align-items-center gap-2'>
            <i class="fa fa-play" aria-hidden="true"></i>
            Run
          </button>
          <div className="btn-timer">
            {minutes.toString().padStart(2, "0")}:
            {seconds.toString().padStart(2, "0")}
          </div>
          <button className='btn btn-submit d-flex align-items-center gap-2' onClick={handleCodeSubmit}>
            <i class="fa fa-cloud-upload" aria-hidden="true"></i>
            Submit
          </button>

        </div>
      </div>
      <div className="editor-body">
        <Split mode="horizontal">
          <div className="d-flex flex-column" style={{ width: '40%' }}>
            <div className="h-100 overflow-auto">
              {jobData?.questionText && <RTE value={jobData?.questionText} readOnly />}
            </div>
          </div>
          <div className="d-flex flex-column" style={{ width: '60%' }}>
            <div className="d-flex align-items-center py-2">
              <select
                className="language-selector"
                onChange={handleLanguageChange}
                value={languageId}
              >
                <option  disabled>Languages</option>
                <option value="javascript">JavaScript</option>
                <option value="typescript">Typescript</option>
                <option value="html">HTML</option>
                <option value="scss">CSS / SCSS</option>
                <option value="cpp">C++</option>
                <option value="java">Java</option>
                <option value="python">Python</option>
                <option value="php">PHP</option>
                <option value="Scala">Scala</option>
                <option value="rust">Rust</option>
                <option value="ruby">Ruby</option>
                <option value="go">Golang</option>
              </select>
            </div>

            <Split mode="vertical" className="flex-1">
              <div style={{ height: '70%' }}>
                <CodeEditor
                  language={languageId}
                  sourceCode={sourceCode}
                  setSourceCode={setSourceCode}
                />
              </div>
              <div class="accordion" id="accordionPapanelsStayOpennelsStayOpenExample" style={{ height: '30%'}}>
                <div class="accordion-item">
                  <div className="d-flex col-12 align-items-center p-2">
                    <div
                      class="w-50 col-6 b-none text-white"
                    >
                      <p style={{ margin: "0", marginLeft: "30px" }}>
                        Console
                      </p>
                    </div>
                  </div>
                  <div
                    id="panelsStayOpen-collapseOne"
                    // class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div class="accordion-body">
                      <div className="terminal">
                        <div>Welcome to JOBIFY Terminal!</div>
                        <br />
                        <div>
                          $ <div>
                            {/* <pre className="terminal-content codeResult >{output}</pre> */}
                            <pre className=
                              {
                                codeResult?.areAllStepsCompletedSuccessfully || false
                                  ? "terminal-content"
                                  : "terminal-content-failed"
                              }
                            >{output}</pre>
                            {
                              <pre className={testMessageClassName}>{testCasesResultMessage}</pre>
                            }
                            {
                              isTestCasesRunning ?
                                <div>Test cases running...</div>
                                :
                                <>
                                  <pre className="failed-tests">
                                    {testCasesResult.failedTestCases}
                                  </pre>
                                  <pre className="passed-tests">
                                    {testCasesResult.passedTestCases}
                                  </pre>
                                </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Split>
          </div>
        </Split>
      </div>

    </div>
  );
};

/*

<FeedbackModal
  isOpen={isModalOpen}
  onRequestClose={handleClose}
  onSubmit={handleSubmit}
  onRatingChange={handleRatingChange}
  onReviewChange={handleReviewChange}
/>

*/

export default Editor;
