import React, { useState } from "react";
import Modal from "react-modal";
import Rinput from "./Rinput";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import axiosInstance from "../axiosInstance";
import { useAppSelector } from "../redux/hooks";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
export default function ApplyNow({ isOpen, onRequestClose, onSubmit, jobId }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [noticeperiod, setNoticePeriod] = useState("");
  const [company, setCompany] = useState("");
  const [currentctc, setCurrentctc] = useState("");
  const [expectedctc, setExpectedctc] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [experience, setexperience] = useState("");
  const [loading, setLoading] = useState(false);

  const { theme } = useAppSelector((state) => state.auth);

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setNoticePeriod("");
    setCompany("");
    setCurrentctc("");
    setExpectedctc("");
    setUploadedFileName("");
    setexperience("")
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFileName(file);
    }
  };

  const handleSubmit = () => {
    if (!firstName || !lastName || !email || !phone || !uploadedFileName) {
      toast.error("Fill all Details");
      return;
    }
    setLoading(true);
    const authData = JSON.parse(localStorage.getItem("auth"));
    const token = authData;
    let details = `${firstName}:${lastName}:${email}:${phone}:${jobId}:${currentctc}:${expectedctc}:${noticeperiod}:${company}:${experience}`;
    let encoded = base64_encode(details);

    const formData = new FormData();
    formData.append("file", uploadedFileName);

    axiosInstance
      .post(`/v1/applications/apply?encodedDetails=${encoded}&token=${token}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        resetForm();
        toast.success("Job Applied Successfully");
        onRequestClose();
      })
      .catch((error) => {
        console.error("Error uploading data:", error);
        // alert("Error uploading data. Please try again.");
        toast.error("Something went wrong. Please try again")
        resetForm();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        className={`jobify-modal-content`}
        overlayClassName={`jobify-modal-overlay theme-${theme}`}
        shouldCloseOnOverlayClick={true}
      >
        <div className="p-3 h-100">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <ClipLoader color="#3498db" loading={loading} size={50} />
            </div>
          ) : (
            <div>
              <div className="mb-3 content">
                <div className="mx-auto upload">
                  {!uploadedFileName ? (
                    <label>
                      <img src="/upload.png" alt="" />
                      <div>Upload Resume</div>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                    </label>
                  ) : (
                    <div>
                      <img
                        src="/uploaded-icon.png"
                        alt="Uploaded Icon"
                        style={{ height: "2rem" }}
                      />
                      <div>Uploaded: {uploadedFileName.name}</div>
                    </div>
                  )}
                </div>
                <div className="d-flex flex-wrap">
                  <div className="w-50 px-2 py-2">
                    <Rinput
                      text="First Name"
                      className="mt-3"
                      value={firstName}
                      onChange={setFirstName}
                    ></Rinput>
                  </div>
                  <div className="w-50 px-2 py-2">
                    <Rinput
                      text="Last Name"
                      className="mt-3"
                      value={lastName}
                      onChange={setLastName}
                    ></Rinput>
                  </div>
                  <div className="w-50 px-2 py-2">
                    <Rinput
                      text="Email"
                      className="mt-3"
                      value={email}
                      onChange={setEmail}
                    ></Rinput>
                  </div>
                  <div className="w-50 px-2 py-2">
                    <Rinput
                      text="Phone"
                      className="mt-3"
                      value={phone}
                      onChange={setPhone}
                    ></Rinput>
                  </div>
                  <div className="w-50 px-2 py-2">
                    <Rinput
                      text="Years of experience"
                      className="mt-3"
                      value={experience}
                      onChange={setexperience}
                    ></Rinput>
                  </div>
                  <div className="w-50 px-2 py-2">
                    <Rinput
                      text="Notice-Period"
                      className="mt-3"
                      value={noticeperiod}
                      onChange={setNoticePeriod}
                    ></Rinput>
                  </div>
                  <div className="w-50 px-2 py-2">
                    <Rinput
                      text="Current/Present Company"
                      className="mt-3"
                      value={company}
                      onChange={setCompany}
                    ></Rinput>
                  </div>
                  <div className="w-50 px-2 py-2">
                    <Rinput
                      text=" Current CTC"
                      className="mt-3"
                      value={currentctc}
                      onChange={setCurrentctc}
                    ></Rinput>
                  </div>
                  <div className="w-50 px-2 py-2">
                    <Rinput
                      text="Expected Ctc"
                      className="mt-3"
                      value={expectedctc}
                      onChange={setExpectedctc}
                    ></Rinput>
                  </div>
                </div>
              </div>
              <button className='mx-2 btn btn-main w-100' onClick={handleSubmit}>Submit</button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
