import { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import image from "../jupiter.png";
import "./JobList.css";
import { FaRegMap } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ApplyNow from "../ApplyNow";
import Modal from "react-modal";
import JobPreview from "../recruiter/JobPreview";
import { useAppSelector } from "../../redux/hooks";
import { ClipLoader } from "react-spinners";

const FILTERLOCATIONS = [
    { label: 'Bangalore', value: 'bangalore' },
    { label: 'Hyderabad', value: 'hyderabad' },
    { label: 'New York', value: 'ny' },
];

export default function JobsList() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [jobInfo, setJobInfo] = useState(null);
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [data, setData] = useState([]);
    const [jobs, setJobs] = useState([]);
    const { theme } = useAppSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [activeStatus, setActiveStatus] = useState("UNDECIDED");
    const [activeLocations, setActiveLocations] = useState(() => {
        return FILTERLOCATIONS.map((location) => location.value);
    });

    const openModal = (jobId) => {
        setSelectedJobId(jobId); // set the selected job ID
        setIsModalOpen(true);
    };

    const openDetailModal = (jobData) => {
        setIsDetailModalOpen(true);
        setJobInfo(jobData);
    };

    const closeDetailModal = () => {
        setIsDetailModalOpen(false);
    };

    const handleApply = () => {
        closeDetailModal();
        openModal(jobInfo.jobPostUUID);
    };

    const handleSubmit = () => {
        setIsModalOpen(false);
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const getJobs = async () => {
        setIsLoading(true);
        try {
            const authToken = JSON.parse(localStorage.getItem("auth"));
            const response = await axiosInstance.get(
                `v1/recruiters/job-postings/candidates-view?token=${authToken}`
            );
            console.log(response);
            setJobs(response.data);
        } catch (error) {
            console.error("Error fetching jobs:", error);
            // Optionally, show an error toast or message
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getJobs();
    }, []);

    useEffect(() => {
        const filtered = jobs.filter(
            (item) =>
                item.status === activeStatus &&
                activeLocations.includes(item.jobLocationCity.toLowerCase())
        );
        setData(filtered);
    }, [jobs, activeStatus, activeLocations]);

    const filterUpdate = (e) => {
        const { name, value, checked } = e.target;
        if (name === "status") {
            setActiveStatus(value);
        } else if (name === "jobLocationCity") {
            let temp = new Set(activeLocations);
            if (checked) {
                if (value === "all") {
                    FILTERLOCATIONS.forEach((location) => {
                        temp.add(location.value);
                    });
                } else {
                    temp.add(value);
                }
                setActiveLocations([...temp]);
            } else {
                temp.delete(value);
                setActiveLocations([...temp]);
            }
        }
    };

    return (
        <>
            <div className="jobs-container">
                <div className="filter-container">
                    <div className="filter-box">
                        <div className="f-head">Filter by status</div>
                        <div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    id="UNDECIDED"
                                    value="UNDECIDED"
                                    onChange={filterUpdate}
                                    checked={activeStatus === "UNDECIDED"}
                                />
                                <label className="form-check-label" htmlFor="UNDECIDED">
                                    Undecided
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    id="APPLIED"
                                    value="APPLIED"
                                    onChange={filterUpdate}
                                    checked={activeStatus === "APPLIED"}
                                />
                                <label className="form-check-label" htmlFor="APPLIED">
                                    Applied
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    id="REJECTED"
                                    value="REJECTED"
                                    onChange={filterUpdate}
                                    checked={activeStatus === "REJECTED"}
                                />
                                <label className="form-check-label" htmlFor="REJECTED">
                                    Rejected
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="filter-box">
                        <div className="f-head">Filter by location</div>
                        <div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="jobLocationCity"
                                    id="all"
                                    value="all"
                                    onChange={filterUpdate}
                                    checked={activeLocations.length === FILTERLOCATIONS.length}
                                />
                                <label className="form-check-label" htmlFor="all">
                                    All
                                </label>
                            </div>
                            {FILTERLOCATIONS.map((location) => (
                                <div className="form-check" key={location.value}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="jobLocationCity"
                                        id={location.value}
                                        value={location.value}
                                        onChange={filterUpdate}
                                        checked={activeLocations.includes(location.value)}
                                    />
                                    <label className="form-check-label" htmlFor={location.value}>
                                        {location.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <ClipLoader color="#3498db" loading={isLoading} size={50} />
                        </div>
                    ) : data.length > 0 ? (
                        data.map((job) => (
                            <div className="job-card d-flex mt-3" key={job.jobPostUUID}>
                                <div className="d-none d-lg-block">
                                    <img src={image} alt="" height={"50px"} />
                                </div>

                                <div className="flex-1">
                                    <div className="row d-flex col-12">
                                        <img
                                            className="d-sm-none col-2 p-0"
                                            src={image}
                                            alt=""
                                        />
                                        <h5 className="col-10">{job.jobTitle}</h5>
                                    </div>
                                    <div className="row d-flex">
                                        <div>
                                            <span style={{ color: "#3B82F6" }}>
                                                {job.companyName}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="job-chips mt-2">
                                        <div className="job-chip">
                                            <FaRegMap className="mr-2" />
                                            {job.jobLocationCity}
                                        </div>
                                        {job.requiredExperience && (
                                            <div className="job-chip">
                                                {job.requiredExperience}+
                                            </div>
                                        )}
                                        {job.contractType && (
                                            <div className="job-chip">{job.contractType}</div>
                                        )}
                                        {job.jobType && (
                                            <div className="job-chip">{job.jobType}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center align-items-center">
                                    <button
                                        type="button"
                                        className="mx-2 btn btn-sub"
                                        style={{ background: "#59B24F", color: "white" }}
                                        onClick={() => openDetailModal(job)}
                                    >
                                        View
                                    </button>
                                    {job.status === "UNDECIDED" && (
                                        <button
                                            type="button"
                                            className="mx-2 btn btn-main"
                                            style={{ background: "#59B24F", color: "white" }}
                                            onClick={() => openModal(job.jobPostUUID)}
                                        >
                                            Apply Now
                                        </button>
                                    )}
                                    {job.status === "APPLIED" && (
                                        <button
                                            type="button"
                                            className="mx-2 btn btn-main"
                                            onClick={() =>
                                                navigate(`/test-arena?jobId=${job.jobPostUUID}`)
                                            }
                                        >
                                            Take Test
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center mt-5">
                            <p>No jobs found matching your criteria.</p>
                        </div>
                    )}
                </div>
                <ApplyNow
                    isOpen={isModalOpen}
                    onRequestClose={handleClose}
                    onSubmit={handleSubmit}
                    jobId={selectedJobId}
                />
                <Modal
                    style={{ zIndex: "100 !important" }}
                    isOpen={isDetailModalOpen}
                    onRequestClose={closeDetailModal}
                    ariaHideApp={false}
                    className={`jobify-modal-content`}
                    overlayClassName={`jobify-modal-overlay theme-${theme}`}
                    shouldCloseOnOverlayClick={true}
                >
                    <JobPreview
                        jobData={jobInfo}
                        isView={true}
                        handleAction2={
                            jobInfo?.status === "UNDECIDED" ? closeDetailModal : null
                        }
                        handleAction1={
                            jobInfo?.status === "UNDECIDED" ? handleApply : null
                        }
                    />
                </Modal>
            </div>
        </>
    );
}
