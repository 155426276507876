// src/axiosInstance.js
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.jobify.club"
});


export default axiosInstance;


