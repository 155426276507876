import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const schema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().required('Required').email('Invalid Email'),
  mobileNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  resume: yup.mixed().required('Required').test({
    message: 'Please provide a supported file type',
    test: (file: any, context) => {
      const isValid = ['pdf', 'docx'].includes(file.name.split('.')[1]);
      if (!isValid) context?.createError();
      return isValid;
    }
  })
})