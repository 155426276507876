import React, { useState } from "react";
import Rpost from "../Rpost";
import ActiveJobs from "../ActiveJobs";
import AppliedCandidates from "../AppliedCandidatePage/AppliedCandidates";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppNav from "../AppNav";
import { TabList, TabPanel, Tab, Tabs } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import CreateJob from "./CreateJob";
import If from "../If";

export default function Rhome() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [selectedJobId, setSelectedJobId] = useState(null);
  const handleJobButtonClick = (jobId) => {
    console.log("edbibei");
    console.log(jobId);
    setSelectedJobId(jobId);
    setActiveItem("applied");
  };
  const [activeItem, setActiveItem] = useState("Post");
  const [isClassic, setIsClassic] = useState(true);

  const renderComponent = () => {
    switch (activeItem) {
      case "Post":
        return <Rpost />;
      case "Active":
        return <ActiveJobs onButtonClick={handleJobButtonClick} />;
      case "Applied":
        return (
          <AppliedCandidates selectedJobId={selectedJobId}></AppliedCandidates>
        );

      default:
        return null;
    }
  };
  const handlesignout = () => {
    localStorage.clear();
    navigate('/login');
  };
  return (
    <div>
      <AppNav showRecruiterTabs />
      <div className="recruiters-view py-4">
        <If condition={searchParams.get('tab') == 1}>
          {/* <div class="form-check form-switch d-flex justify-content-center">
            <input class="form-check-input" type="checkbox" role="switch" onChange={(e) => setIsClassic(!isClassic)} id="flexSwitchCheckDefault" checked={isClassic} />
            <label class="form-check-label m-2" for="flexSwitchCheckDefault">Classic</label>
          </div> */}
          <div className={`h-100 ${isClassic && 'hide'}`}>
            <Rpost />
          </div>
          <div className={`h-100 ${!isClassic && 'hide'}`}>
            <CreateJob />
          </div>
        </If>
        <If condition={searchParams.get('tab') == 2}>
          <ActiveJobs onButtonClick={handleJobButtonClick} />
        </If>
      </div>
    </div>
  );
}

{/* <Tabs>
            <TabList>
              <Tab>Post a Job</Tab>
              <Tab>Active Jobs</Tab>
            </TabList>

            <TabPanel className="recruiters-view-job-creation">
              <div class="form-check form-switch d-flex justify-content-center">
                <input class="form-check-input" type="checkbox" role="switch" onChange={(e) => setIsClassic(!isClassic)} id="flexSwitchCheckDefault" checked={isClassic} />
                <label class="form-check-label m-2" for="flexSwitchCheckDefault">Classic</label>
              </div>
              <div className={`h-100 ${isClassic && 'hide'}`}>
                <Rpost />
              </div>
              <div className={`h-100 ${!isClassic && 'hide'}`}>
                <CreateJob />
              </div>
            </TabPanel>
            <TabPanel className="recruiters-view-active-jobs">
              <ActiveJobs onButtonClick={handleJobButtonClick} />
            </TabPanel>
          </Tabs> */}
          {/* <div
            className="col-3"
            style={{
              background: "#EFF5F6",
              borderRadius: "1rem",
              height: "80vh",
            }}
          >
            <div className="row">

            </div>

            <div
              className="row p-2 d-flex justify-content-center align-items-center my-auto mt-5"
              onClick={(e) => {
                e.preventDefault();
                setActiveItem("post");
              }}
              style={
                activeItem === "post"
                  ? { color: "#01BDF8", height: "50px", cursor: "pointer" }
                  : { height: "50px", cursor: "pointer" }
              }
            >
              Post Jobs
            </div>
            <div
              className="row p-2 d-flex justify-content-center align-items-center"
              onClick={(e) => {
                e.preventDefault();
                setActiveItem("active");
              }}
              style={
                activeItem === "active"
                  ? { color: "#01BDF8", height: "50px", cursor: "pointer" }
                  : { height: "50px", cursor: "pointer" }
              }
            >
              Active Jobs
            </div>{" "}

          </div> */}
          {/* {renderComponent()} */}