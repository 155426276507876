export const DEFAULT_QUESTION = `<p>Given an input string <code>s</code> and a pattern <code>p</code>, implement regular expression matching with support for <code>'.'</code> and <code>'*'</code> where:</p>
<ul>
<li><code>'.'</code> Matches any single character.​​​​</li>
<li><code>'*'</code> Matches zero or more of the preceding element.</li>
</ul>
<p>The matching should cover the <strong>entire</strong> input string (not partial).</p>
<p><strong>Example 1:</strong></p>
<pre><strong>Input:</strong> s = "aa", p = "a"<br><strong>Output:</strong> false<br><strong>Explanation:</strong> "a" does not match the entire string "aa".</pre>
<p><strong>Example 2:</strong></p>
<pre><strong>Input:</strong> s = "aa", p = "a*"<br><strong>Output:</strong> true<br><strong>Explanation:</strong> '*' means zero or more of the preceding element, 'a'. Therefore, by repeating 'a' once, it becomes "aa".</pre>
<p><strong>Example 3:</strong></p>
<pre><strong>Input:</strong> s = "ab", p = ".*"<br><strong>Output:</strong> true<br><strong>Explanation:</strong> ".*" means "zero or more (*) of any character (.)".</pre>
<p><strong>Constraints:</strong></p>
<ul>
<li><code>1 &lt;= s.length &lt;= 20</code></li>
<li><code>1 &lt;= p.length &lt;= 20</code></li>
<li><code>s</code> contains only lowercase English letters.</li>
<li><code>p</code> contains only lowercase English letters, <code>'.'</code>, and <code>'*'</code>.</li>
<li>It is guaranteed for each appearance of the character <code>'*'</code>, there will be a previous valid character to match.</li>
</ul>`;

export const DEFAULT_JOB_DATA = {
  "companyName": "Jobify Inc",
  "companySize": "100",
  "companyWebsiteUrl": "https://www.jobify.club/",
  "companyLinkedInUrl": "https://www.linkedin.com/in/muneeshduraipandi/",

  "recruiterName": "Vimalraj J",
  "recruiterEmail": "dhanushvimal001@gmail.com",

  "jobLocationAddress": "31, sanjeev gandhi street",
  "jobLocationCity": "BANGALORE",
  "jobLocationState": "Andhra",
  "jobLocationCountry": "India",
  
  "companyLocationAddress": "31, sanjeev gandhi street",
  "companyLocationCity": "BANGALORE",
  "companyLocationState": "Andhra",
  "companyLocationCountry": "India",
  
  "jobTitle": "Software Engineer(Backend/Frontend)",
  "requiredExperience": "8",
  "jobType": "Remote / Hybrid / onsite",
  "contractType": "Full time",
  "compensationFrom": "600000",
  "compensationTo": "10000000",
  "ctcDetailsIfAny": "600000 - 10000000",
  "additionalCompensation": "200000",
  "jobPostUUID": "487d05a8-f483-4d6a-9cc8-a44ca226af6a",
  "jobDescription": `<p><strong>About this role</strong></p>
  <p>As an UI/UX Designer on Pixelz Studio, you'll focus on design user-friendly on several platform (web, mobile, dashboard, etc) to our users needs. Your innovative solution will enhance the user experience on several platforms. Join us and let's making impact on user engagement at Pixelz Studio.</p>
  <p><strong>Qualification</strong></p>
  <ul>
    <li>At least 2-4 years of relevant experience in product design or related roles.</li>
    <li>Knowledge of design validation, either through quantitative or qualitative research.</li>
    <li>Have good knowledge using Figma and Figjam</li>
    <li>Experience with analytics tools to gather data from users.</li>
  </ul>
  <p><strong>Responsibility</strong></p>
  <ul>
    <li>Create design and user journey on every features and product/business units across multiples devices (Web+App)</li>
    <li>Identifying design problems through user journey and devising elegant solutions</li>
    <li>Develop low and hi fidelity designs, user experience flow, &amp; prototype, translate it into highly-polished visual composites following style and brand guidelines.</li>
    <li>Brainstorm and works together with Design Lead, UX Engineers, and PMs to execute a design sprint on specific story or task</li>
  </ul>`,
  "questionText": DEFAULT_QUESTION,

  "jobPositions": "10",
  "workingDays": "5",
  "noticeperiod": "30",
  "skills": "Java, react, bootstrap",
  "status": null,
  "createdAt": 1699473182086,
  "updatedAt": 1699473182086,
  "createdBy": "bmurasing@gmail.com",
  "updatedBy": "bmurasing@gmail.com",
  "deleted": false
}