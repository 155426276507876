import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormFooter from './FormFooter';
import './index.scss';

function FormExample({ formData, onDataChange, handleNext, handleBack }) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            handleNext();
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        onDataChange((prev) => { return { ...prev, [name]: value } });
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>How many hires do you require for this job?</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Number of positions"
                        name="jobPositions"
                        value={formData.jobPositions}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>How urgently do you need to make a hire?</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Notice period"
                        name="noticeperiod"
                        value={formData.noticeperiod}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Monday-Saturday</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Working days"
                        name="workingDays"
                        value={formData.workingDays}
                        onChange={handleChange}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Skills</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Provide skills with comma seperated"
                        defaultValue=""
                        name="skills"
                        value={formData.skills}
                        onChange={handleChange}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <FormFooter formData={formData} showPrev={true} handleBack={handleBack} />
        </Form>
    );
}

export default FormExample;