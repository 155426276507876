export default function FormFooter({ showPrev, isFinal, handleBack, previewJob, formData }) {
    return <div className="form-footer">
        <div className='preview-footer-card'>
            <div>
                <h6 className='title'>{formData.jobTitle}</h6>
                <div className='posted-by'>Job posted by {formData.recruiterName}(HR) </div>
            </div>
            <div>
                {showPrev ? <button type="button" className='mx-2 btn btn-sub' onClick={handleBack}>Prev</button> : null}
                {!isFinal ? <button type="submit" className='mx-2 btn btn-main'>Next</button> : null}
                {isFinal ? <button type="button" className='mx-2 btn btn-main' onClick={previewJob}>Preview and Publish Job</button> : null}
            </div>
        </div>
    </div>
}
