import React, { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { encode as base64_encode } from "base-64";
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import { useAppDispatch } from '../../redux/hooks';
import { setTheme } from '../../redux/slices/auth/authSlice';
import RefreshIcon from '../../assets/images/refresh.png';
import AppInput from '../AppInput';
import './index.scss';
import { schema } from './data';
import If from '../If';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

const PreTestArena = () => {
    const [file, setFile] = useState<any>();
    const [otp, setOtp] = useState('');
    const [isOtpGenerated, setIsOtpGenerated] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const { handleSubmit, register, formState: { errors }, setValue, getValues, watch } = useForm<any>({
        resolver: yupResolver(schema),
        mode: 'all'
    });

    useEffect(() => {
        dispatch(setTheme('dark'));
    }, []);

    useEffect(() => {
        console.log(otp);
        if(otp.length === 6) {
            verifyOtp();
        }
    }, [otp]);

    const handleFileChange = (event: any) => {
        const files = event.target.files;
        setFile(files[0])
        setValue('resume', files[0]);
    }

    const resetFile = () => {
        setFile(null);
        setValue('resume', null);
    }

    const onSubmit = async (values: any) => {
        if(!isOtpVerified) {
            toast.error('OTP not verified. Please generate again');
            return;
        }
        console.log(values);
        const body = {...values};
        delete body.resume;

        const formdata = new FormData();
        formdata.append('resume', values.resume);
        formdata.append('requestBody', base64_encode(JSON.stringify(body)));

        const response = await axios.post(`https://api.jobify.club/api/v3/jobs/${searchParams.get('jobId')}/apply?token=${localStorage.getItem('auth')}`, formdata);
        navigate(`/test-arena?jobId=${searchParams.get('jobId')}`)
    }

    const sendOTP = async() => {
        try {
            // const response = await verifyEmailWithJobId();
            // console.log(response);
            await generateOtp();
            setIsOtpGenerated(true);
            toast.success('OTP Generated successfully');
        } catch(err: any) {
            console.log('>>>error', err);
            if(err?.response?.data?.message) {
                toast.error(err.response.data.message);
            }
        }
    }

    /*const verifyEmailWithJobId = () => {
        const values = getValues();
        return axios.get(`https://api.jobify.club/v1/jobs/candidates/verify?email=${values.email}&jobPostUUID=${searchParams.get('jobId')}`)
    }*/

    const generateOtp = () => {
        const values = getValues();
        return axios.post(`https://api.jobify.club/v1/apis/login/otp?email=${values.email}&isRecruiter=false`)
    }

    const verifyOtp = async() => {
        try {
            const values = getValues();
            const response  = await axios.post(`https://api.jobify.club/v1/apis/login/otp/verify?email=${values.email}&otp=${otp}&jobPostUUID=${searchParams.get('jobId')}`);
            setIsOtpVerified(true);
            localStorage.setItem('auth', response.data._token);
            toast.success('OTP Verified successfully');
            // axios.defaults.headers.common.Authorization = `Bearer ${response.data._token}`;
            console.log(response);
        } catch(err: any) {
            console.log(err);
            if(err.response.data) {
                setIsOtpVerified(false);
                toast.error(err.response.data);
            }
        }
    }


    const email = watch('email');
    return (
        <div className='resume_upload_container'>
            <section className='input_form'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h5>User Details</h5>
                    <div className='sub-heading'>An updated resume is key to being shortlisted</div>
                    <div className='tw-flex tw-flex-wrap tw-gap-y-4 tw-my-4'>
                        <div className='sm:tw-w-1/2 tw-pr-4'>
                            <AppInput name='firstName' label='' placeholder='First name*' register={register} errors={errors} required />
                        </div>
                        <div className='sm:tw-w-1/2 tw-pr-4'>
                            <AppInput name='lastName' label='' placeholder='Last name*' register={register} errors={errors} required />
                        </div>
                        <div className='sm:tw-w-1/2 tw-pr-4'>
                            <AppInput name='mobileNumber' label='' placeholder='Phone no*' register={register} errors={errors} required />
                        </div>
                        <div className='sm:tw-w-1/2 tw-pr-4'>
                            <AppInput name='email' label='' placeholder='Email*' register={register} errors={errors} required />
                            {email && !errors.email ? <div className='get_otp' onClick={sendOTP}>Get OTP</div> : null}
                        </div>
                        {isOtpGenerated ? <div className='tw-w-full'>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} className='tw-flex-1'/>}
                            />
                        </div> : null}
                    </div>
                    <h6 className='tw-my-3'>Upload your updated resume:</h6>
                    <div className='sub-heading'>If you don't have an updated resume, we strongly suggest you create one.
                        Otherwise upload a PDF of your updated LinkedIn profile
                    </div>
                    <div>
                        <If condition={errors?.resume}>
                            <div className="error tw-ml-0.5 tw-text-sm">{errors?.resume?.message as ReactNode}</div>
                        </If>
                        {!file ?
                            <input className="tw-relative tw-mt-2 file_upload" name="company_logo" type="file" id="file"
                                onChange={handleFileChange} accept=".pdf" /> :
                            <div className='uploaded_file tw-mt-3 tw-mb-6'>
                                <span>{file?.name}</span>
                                <img src={RefreshIcon} onClick={resetFile} className='tw-cursor-pointer'/>
                            </div>} {/* .doc,.docx,.ppt,.pptx,.txt, */}
                    </div>
                    <button type="submit" className="mx-2 btn btn-main tw-w-full">Submit</button>
                </form>
            </section>
        </div>
    )
}

export default PreTestArena;