import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const getUser = () => {
  if(localStorage.getItem("user")) {
    return JSON.parse(localStorage.getItem("user") || '{}');
  } else {
    return null;
  }
}

const initialState = {
  user: getUser(),
  loading: false,
  token: null,
};


export type  IAuthState = {
  user: any;
  loading: boolean,
  token: string | null,
  theme: 'light' | 'dark';
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState as IAuthState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.token = action.payload._token;

      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem("auth", JSON.stringify(action.payload._token));
      localStorage.setItem("email", JSON.stringify(action.payload.email));
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setTheme: (state, action: PayloadAction<'light' | 'dark'>) => {
      state.theme = action.payload;
      localStorage.setItem('theme', action.payload);
    },
    logoutUser: (state, action: PayloadAction<boolean>) => {
      state.user = null;
      state.token = null;
      localStorage.clear();
    },
  },
});

export const { setUser, setLoading, setToken, setTheme, logoutUser } = authSlice.actions;
export const authReducer = authSlice.reducer;