import React from "react";
import './Rinput.scss';

export default function Rinput({ text, placeholdertext, value, onChange }) {
  return (
    <div className="input-container">
      <label for="hires" className="labelinput">
        {text}
      </label>
      <input
        type="text"
        class="input-text"
        aria-label="Sizing example input"
        aria-describedby="inputGroup-sizing-default"
        placeholder={placeholdertext}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}
